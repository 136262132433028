#funded-training {
    section {
        padding: 70px 0;
        overflow: hidden;
    }

    .section-bg {
        background-color: #f1fafd;
    }

    .section-title {
        text-align: center;
        padding-bottom: 30px;
    }

    .section-title h2 {
        font-size: 32px;
        font-weight: bold;
        margin-bottom: 20px;
        padding-bottom: 20px;
        position: relative;
        color: #2717bd;
    }

    .section-title h2::before {
        content: "";
        position: absolute;
        display: block;
        width: 120px;
        height: 1px;
        background: #ddd;
        bottom: 1px;
        left: calc(50% - 60px);
    }

    .section-title h2::after {
        content: "";
        position: absolute;
        display: block;
        width: 40px;
        height: 3px;
        background: #2717BD;
        bottom: 0;
        left: calc(50% - 20px);
    }

    .section-title p {
        margin-bottom: 0;
    }

    .about .icon-boxes h4 {
        font-size: 18px;
        margin-bottom: 15px;
    }

    .about .icon-boxes h3 {
        font-size: 28px;
        font-weight: 700;
        color: #2717bd;
        margin-bottom: 15px;
    }

    .about .icon-box {
        margin-top: 40px;
    }

    .about .icon-box .icon {
        float: left;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 64px;
        height: 64px;
        border: 2px solid #4c38c9;
        border-radius: 50px;
        transition: 0.5s;
    }

    .about .icon-box .icon i {
        color: #2717BD;
        font-size: 32px;
    }

    .about .icon-box .icon i svg path {
        stroke: #2717BD;
    }

    .about .icon-box:hover .icon {
        background: #2717BD;
        border-color: #2717BD;
    }

    .about .icon-box:hover .icon i {
        color: #fff;
    }

    .about .icon-box:hover .icon i svg path {
        stroke: #fff;
    }

    .about .icon-box .title {
        font-weight: 700;
        margin-bottom: 0px;
        font-size: 18px;
        margin-left: 20px;
        color: #343a40;
    }

    .about .icon-box .title a {
        color: #343a40;
        transition: 0.3s;
    }

    .about .icon-box .title a:hover {
        color: #2717BD;
    }

    .about .icon-box .description {
        margin-left: 85px;
        line-height: 24px;
        font-size: 14px;
    }

    .about .video-box {
        // background: url("../img/about.jpg") center center no-repeat;
        background-size: cover;
        min-height: 500px;
    }

    .about .play-btn {
        width: 94px;
        height: 94px;
        background: radial-gradient(#2717BD 50%, rgba(25, 119, 204, 0.4) 52%);
        border-radius: 50%;
        display: block;
        position: absolute;
        left: calc(50% - 47px);
        top: calc(50% - 47px);
        overflow: hidden;
    }

    .about .play-btn::after {
        content: "";
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translateX(-40%) translateY(-50%);
        width: 0;
        height: 0;
        border-top: 10px solid transparent;
        border-bottom: 10px solid transparent;
        border-left: 15px solid #fff;
        z-index: 100;
        transition: all 400ms cubic-bezier(0.55, 0.055, 0.675, 0.19);
    }

    .about .play-btn::before {
        content: "";
        position: absolute;
        width: 120px;
        height: 120px;
        -webkit-animation-delay: 0s;
        animation-delay: 0s;
        -webkit-animation: pulsate-btn 2s;
        animation: pulsate-btn 2s;
        -webkit-animation-direction: forwards;
        animation-direction: forwards;
        -webkit-animation-iteration-count: infinite;
        animation-iteration-count: infinite;
        -webkit-animation-timing-function: steps;
        animation-timing-function: steps;
        opacity: 1;
        border-radius: 50%;
        border: 5px solid rgba(25, 119, 204, 0.7);
        top: -15%;
        left: -15%;
        background: rgba(198, 16, 0, 0);
    }

    .about .play-btn:hover::after {
        border-left: 15px solid #2717BD;
        transform: scale(20);
    }

    .about .play-btn:hover::before {
        content: "";
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translateX(-40%) translateY(-50%);
        width: 0;
        height: 0;
        border: none;
        border-top: 10px solid transparent;
        border-bottom: 10px solid transparent;
        border-left: 15px solid #fff;
        z-index: 200;
        -webkit-animation: none;
        animation: none;
        border-radius: 0;
    }

    @-webkit-keyframes pulsate-btn {
        0% {
            transform: scale(0.6, 0.6);
            opacity: 1;
        }

        100% {
            transform: scale(1, 1);
            opacity: 0;
        }
    }

    @keyframes pulsate-btn {
        0% {
            transform: scale(0.6, 0.6);
            opacity: 1;
        }

        100% {
            transform: scale(1, 1);
            opacity: 0;
        }
    }

}
@primary-color: #2717bd;@themeColor: #2717bd;@font-family: 'Poppins', sans-serif;