.login-mb-40 {
    margin-bottom: 40px !important;
}

.auth-modal .ant-modal-footer {
    padding: 24px 72px;
}

.auth-modal .ant-modal-header {
    padding-left: 72px;
    padding-right: 72px;
}

.auth-modal .ant-modal-body {
    padding-left: 72px;
    padding-right: 72px;
}
@primary-color: #2717bd;@themeColor: #2717bd;@font-family: 'Poppins', sans-serif;