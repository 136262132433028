#faq_page {
    section {
        padding: 70px 0;
        overflow: hidden;
    }

    .section-bg {
        background-color: #f1fafd;
    }

    .section-title {
        text-align: center;
        padding-bottom: 30px;
    }

    .section-title h2 {
        font-size: 32px;
        font-weight: bold;
        margin-bottom: 20px;
        padding-bottom: 20px;
        position: relative;
        color: #2717bd;
    }

    .section-title h2::before {
        content: "";
        position: absolute;
        display: block;
        width: 120px;
        height: 1px;
        background: #ddd;
        bottom: 1px;
        left: calc(50% - 60px);
    }

    .section-title h2::after {
        content: "";
        position: absolute;
        display: block;
        width: 40px;
        height: 3px;
        background: #2717BD;
        bottom: 0;
        left: calc(50% - 20px);
    }

    .section-title p {
        margin-bottom: 0;
    }

    .faq .faq-list {
        padding: 0 100px;
    }

    .faq .faq-list .ant-collapse-borderless {
        background: transparent;
    }

    .faq .faq-list .ant-collapse-item {
        background: #f1fafd;
        margin-bottom: 20px;
        border: none;
    }

    .faq .faq-list .ant-collapse-header {
        padding: 15px;
        font-weight: 500;
    }

    .faq .faq-list .ant-collapse-header span{
        vertical-align: middle;
    }

    .faq .faq-list li+li {
        margin-top: 15px;
    }

    .faq .faq-list li {
        padding: 20px;
        border-radius: 4px;
        position: relative;
    }

    .faq .faq-list a {
        display: block;
        position: relative;
        font-family: "Poppins", sans-serif;
        font-size: 16px;
        line-height: 24px;
        font-weight: 500;
        padding: 0 30px;
        outline: none;
        cursor: pointer;
    }

    .faq .faq-list .icon-help {
        font-size: 24px;
        position: absolute;
        right: 0;
        left: 20px;
        color: #76b5ee;
    }

    .faq .faq-list .icon-show,
    .faq .faq-list .icon-close {
        font-size: 24px;
        position: absolute;
        right: 0;
        top: 0;
    }

    .faq .faq-list p {
        margin-bottom: 0;
        padding: 10px 0 0 0;
    }

    .faq .faq-list .icon-show {
        display: none;
    }

    .faq .faq-list a.collapsed {
        color: #343a40;
    }

    .faq .faq-list a.collapsed:hover {
        color: #2717BD;
    }

    .faq .faq-list a.collapsed .icon-show {
        display: inline-block;
    }

    .faq .faq-list a.collapsed .icon-close {
        display: none;
    }

    @media (max-width: 1200px) {
        .faq .faq-list {
            padding: 0;
        }
    }
}
@primary-color: #2717bd;@themeColor: #2717bd;@font-family: 'Poppins', sans-serif;