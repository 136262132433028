#contactus {

    section {
        padding: 70px 0;
        overflow: hidden;
    }

    .section-bg {
        background-color: #f1fafd;
    }

    .section-title {
        text-align: center;
        padding-bottom: 30px;
    }

    .section-title h2 {
        font-size: 32px;
        font-weight: bold;
        margin-bottom: 20px;
        padding-bottom: 20px;
        position: relative;
        color: #2717bd;
    }

    .section-title h2::before {
        content: "";
        position: absolute;
        display: block;
        width: 120px;
        height: 1px;
        background: #ddd;
        bottom: 1px;
        left: calc(50% - 60px);
    }

    .section-title h2::after {
        content: "";
        position: absolute;
        display: block;
        width: 40px;
        height: 3px;
        background: #2717BD;
        bottom: 0;
        left: calc(50% - 20px);
    }

    .section-title p {
        margin-bottom: 0;
    }
    .contact .info {
        width: 100%;
    }

    .contact .info i {
        font-size: 20px;
        color: #2717BD;
        float: left;
        width: 44px;
        height: 44px;
        background: #DCEEF4;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 50px;
        transition: all 0.3s ease-in-out;
    }

    .contact .info h4 {
        padding: 0 0 0 60px;
        font-size: 22px;
        font-weight: 600;
        margin-bottom: 5px;
        color: #2717bd;
    }

    .contact .info p {
        padding: 0 0 0 60px;
        margin-bottom: 0;
        font-size: 14px;
        color: #4b7dab;
    }

    .contact .info .email,
    .contact .info .phone {
        margin-top: 40px;
    }

    .contact .info .email:hover i,
    .contact .info .address:hover i,
    .contact .info .phone:hover i {
        background: #2717BD;
        color: #fff;
    }

    .contact .php-email-form {
        width: 100%;
    }

    .contact .php-email-form .form-group {
        padding-bottom: 8px;
    }

    .contact .php-email-form .error-message {
        display: none;
        color: #fff;
        background: #ed3c0d;
        text-align: left;
        padding: 15px;
        font-weight: 600;
    }

    .contact .php-email-form .error-message br+br {
        margin-top: 25px;
    }

    .contact .php-email-form .sent-message {
        color: #fff;
        background: #18d26e;
        text-align: center;
        padding: 15px;
        font-weight: 600;
    }

    .contact .php-email-form .loading {
        display: none;
        background: #fff;
        text-align: center;
        padding: 15px;
    }

    .contact .php-email-form .loading:before {
        content: "";
        display: inline-block;
        border-radius: 50%;
        width: 24px;
        height: 24px;
        margin: 0 10px -6px 0;
        border: 3px solid #18d26e;
        border-top-color: #eee;
        -webkit-animation: animate-loading 1s linear infinite;
        animation: animate-loading 1s linear infinite;
    }

    .contact .php-email-form input,
    .contact .php-email-form textarea {
        border-radius: 0;
        box-shadow: none;
        font-size: 14px;
    }

    .contact .php-email-form input {
        height: 44px;
    }

    .contact .php-email-form textarea {
        padding: 10px 12px;
    }

    .contact .php-email-form button[type="submit"] {
        background: #4c38c9;
        border: 0;
        padding: 10px 35px;
        color: #fff;
        transition: 0.4s;
        border-radius: 50px;
        height: auto;
    }

    .contact .php-email-form button[type="submit"]:hover {
        background: #2717BD;
    }
}

@primary-color: #2717bd;@themeColor: #2717bd;@font-family: 'Poppins', sans-serif;