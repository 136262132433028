.display-html {
    img {
        width: 100%;
        height: auto;
    }
}

.course-menu h5 {
    background-color: #603EE8;
    margin-top: -1px;
}
@primary-color: #2717bd;@themeColor: #2717bd;@font-family: 'Poppins', sans-serif;